import { useHistory } from 'react-router-dom'
import NotFound from '@/assets/images/notFound/404.png'
import './index.less'

export default function Error() {
    const history = useHistory();
    const goback = () => {
        history.replace("/")
    }
    return (
        <div className='notFound'>
            <img src={NotFound} />
            <span onClick={goback}>回到首页</span>
        </div>
    )
}
