import WarpCom from '@/components/WarpCom'
import ToggleFont from "@/components/ToggleFont";
type Itype = {
    name: string;
    value: string;
    isRef?: boolean;
    refInfo?: any;
}

export default function Detail(props: { title: string; details: any, history: any }) {
    const { title, details, history } = props;
    return (
        <WarpCom>
            {
                title && <h4>{title}</h4>
            }
            <div className="content">
                {
                    details ? details.map((item: Itype, index: number) => {
                        return (
                            <div className={title ? "kv" : (index === 0 ? "kv notitle" : "kv")} key={index}>
                                <span>{item.name}</span>
                                <ToggleFont isRef={item.isRef} refInfo={item.refInfo} history={history}>{item.value}</ToggleFont>
                            </div>
                        )
                    }) : (
                        <div className="loadMore" style={{
                            marginBottom: '-10px',
                            marginTop: '-5px'
                        }}><span className="noData">暂无数据</span></div>
                    )
                }
            </div>
        </WarpCom>
    )
}
