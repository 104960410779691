import { useState, useImperativeHandle } from 'react'
import { Modal, Toast } from 'antd-mobile';
import { Document, Page, pdfjs } from 'react-pdf';
import { getFileBase64 } from '@/api/file';
import './FilePreview.less'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imgType = ['webp', 'png', 'bmp', 'gif', 'jpg', 'jpeg']
export default function FilePreview(props: any) {
    const { fileRef } = props;
    const [fileName, setFileName] = useState('查看文件');
    const [isShow, setIsShow] = useState(false);
    const [base, setBase] = useState('');
    const [totalPage, setTotalPage] = useState(1);
    // 图片路径 bpmn text zip
    const [imgUrl, setImgUrl] = useState('');
    useImperativeHandle(fileRef, () => ({
        show: async (title: string, url_s: string) => {
            const urlReq = '/api' + url_s.split('spi')[1]
            const arr = title.split('.');
            // 文件名
            setFileName(title)
            const res = await getFileBase64(urlReq)
            if (!res.data) {
                Toast.info(res.message);
                return false;
            }
            const { base64, fileName, url } = res.data;
            if (url) {
                if (imgType.includes(arr[1].toLowerCase())) {
                    // eslint-disable-next-line no-undef
                    setImgUrl(`${process.env.REACT_APP_URL}${url}`);
                    setIsShow(true);
                } else {
                    const ua = window.navigator.userAgent.toLowerCase();
                    const match = ua.match(/MicroMessenger/i);
                    if (match?.includes('micromessenger')) {
                        window.location.href = `https://www.qdhtjk.com:8182/${url}`
                    } else {
                        window.open(`https://www.qdhtjk.com:8182/${url}`)
                    }
                }
            } else {
                setBase(`data:application/pdf;base64,${base64}`)
                setIsShow(true);
            }
        }
    }));
    const onClose = () => {
        setIsShow(false)
    }
    const handleOk = () => {
        onClose();
        //处理setData异步问题
        setTimeout(() => {
            setBase('');
            setImgUrl('');
            setTotalPage(1);
        }, 0);
    }
    const onDocumentLoadSuccess = (res: any) => {
        setTotalPage(res._pdfInfo.numPages)
    }
    return (
        <Modal
            className="fileClassName"
            visible={isShow}
            transparent
            maskClosable={false}
            onClose={onClose}
            title={fileName}
            footer={[{ text: '确定', onPress: handleOk }]}
        >
            <div className='fileContent'>
                {
                    !imgUrl ? <Document
                        options={{
                            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                            cMapPacked: true,
                            // useSystemFonts: true,
                            // disableFontFace: true,
                            isEvalSupported: true,
                        }}
                        file={base}
                        onLoadSuccess={onDocumentLoadSuccess}//这个是回调成功的函数
                        loading={<div>请稍等，正在加载中!</div>}>
                        {
                            new Array(totalPage).fill('').map((_: undefined, index: number) => {
                                return (
                                    <Page key={index} pageNumber={index + 1} />
                                )
                            })
                        }
                    </Document> : <img src={imgUrl} />
                }
            </div>
        </Modal>
    )
}
