import { useRequest } from 'ahooks';
import { useRef, memo } from 'react';
import { inject, observer } from 'mobx-react';
import { userLogin } from '@/api/getWeChatCode';
import './index.less';
import { getList } from '@/api/other';
interface IArrItem {
    isSign: boolean;
    name: string;
    value: string;
}
interface ListItem {
    businessId: string;
    path: string;
    title: string;
    url: string;
    parameter: IArrItem[]
}
interface Result {
    pageNo: number;
    pageSize: number;
    total: number;
    list?: ListItem[];
}
type K = {
    name: string;
    value: string;
}
function List(props: { props: { history: any; }; appStore: { setBaseInfo: any; }; }) {
    const containerRef = useRef<HTMLDivElement>(null);
    const { history } = props.props;
    const { setBaseInfo } = props.appStore;
    const fullUrl = window.location.search.split('?getDataUrl=spi')[1];
    if (!fullUrl) {
        return <>返回登录页</>
    }
    const cutUrl = fullUrl && fullUrl.split('&openId=');
    const url: string = "/api" + cutUrl[0];
    const baseInfo = localStorage.getItem('baseInfo');

    useRequest(async () => {
        if (cutUrl[1] && !baseInfo) {
            const useinfo = await userLogin({ openId: cutUrl[1] });
            const { token, userId } = useinfo.data;
            //再次保存 保存userId、token  
            setBaseInfo({ token, userId });
        }
        run(undefined);
    });
    const goto = (url: string) => {
        return () => {
            history.push(`${window.location.pathname}/detail?getDataUrl=${url}`);
        }
    }
    const goBack = () => {
        history.replace('/');
    }

    const { run, data, noMore } = useRequest(
        (d: Result | undefined) => {
            return getList(url, {
                pageNo: (d?.pageNo || 0) + 1,
                pageSize: 10,
                count: d?.total || 0
            })
        },
        {
            manual: true,
            loadMore: true,
            ref: containerRef,
            isNoMore: (d) => {
                const { pageNo, pageSize, total } = d || { pageNo: 1, pageSize: 10, total: 10 };
                return pageSize * pageNo >= total
            },
            formatResult: res => {
                return {
                    pageNo: res?.data?.pageNo,
                    pageSize: res?.data?.pageSize,
                    total: res?.data?.count,
                    list: res?.data?.list
                };
            }
        }
    );
    const { list = [] } = data || {};
    return (
        <>
            <span className='goBackHome' onClick={goBack}> &lt; 返回首页</span>
            <div className="listContainer" ref={containerRef}>
                {list.map((item, index) => (
                    <div className="item" key={index} onClick={goto(item.url)}>
                        <h3 className="title">{item.title}</h3>
                        <p>
                            {
                                item.parameter.map((pItem: K, index: number) =>
                                    <span key={index}>{pItem.name}:{pItem.value}</span>
                                )
                            }
                        </p>
                    </div>
                ))}
                <div className="bottomLoading">
                    {!noMore ? null : <span>暂无更多数据</span>}
                </div>
            </div>
        </>
    );
};
export default inject('appStore')(observer(List));
