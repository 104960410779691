import { Toast } from 'antd-mobile';
import qs from 'qs';
import store from '@/models';


//请求白名单(不需要token)
// const whiteList = ["/api/wx/getCode", "/api/userLogin"];

// 判断返回状态码
const checkStatus = (res: { status: number; statusText: string }) => {
  if (res.status>=200 && res.status<300) { //成功:0
    return res;
  }
  Toast.info(res.statusText, 1);
  throw new Error(res.statusText);
}

//返回数据判断
const judgeOkState = async (res: any) => {
  let baseInfo:any = localStorage.getItem('baseInfo');
  let openId = localStorage.getItem('openid');
  const cloneRes = await res.clone().json();
  if (!baseInfo && !openId) {
    Toast.hide();
    Toast.info('请重新登录，进行信息绑定', 1, () => {
      store.appStore.setBaseInfo({ token:'', userId:'' });
      window.location.href = "/login";
    });
  }
  // 无授权账户
  if (cloneRes.status === -7) {
      Toast.hide();
      Toast.info('请重新登录，进行信息绑定', 1, () => {
        store.appStore.setBaseInfo({ token:'', userId:'' });
        window.location.href = "/login";
      });
      return false;
  }
  // token失效
  if (cloneRes.status === -6) {
      const baseInfo = await request("/api/userLogin", {
        method: 'get',
        params: {
          openId
        }
      });
      const { token, userId } = baseInfo.data;
      //再存一下
      store.appStore.setBaseInfo({ token, userId });
      window.location.reload();
  }
  return res;
}

//失败捕获
const handleError = (error: any) => {
  Toast.hide();
  if (error instanceof TypeError) {
    Toast.info('请重新登录', 1, () => {
      store.appStore.setBaseInfo({ token:'', userId:'' });
      window.location.href = "/login";
    });
    return false;
  }
  return {
    status: -1,
    message:'请求错误，请稍后再试'
  }
}
// 使用 fetch 请求
const request = (url = "", options: any = {}) => {
  Toast.loading('加载中...',0);
  let token = "";
  let baseInfo: any = localStorage.getItem('baseInfo');
  if (baseInfo) {
    baseInfo = JSON.parse(baseInfo);
    token = baseInfo["token"];
  }
  const defaultOptions = {
    /*允许携带cookies*/
    credentials: 'include',
    /*允许跨域**/
    mode: 'cors',
    headers: {
      token,
      // 当请求方法是POST，如果不指定content-type是其他类型的话，默认为如下↓，要求参数传递样式为 key1=value1&key2=value2，但实际场景以json为多
      'content-type': 'application/json;charset=utf-8',
    },
  };
  if (options.method.toUpperCase() === "POST") {
    // POST 特殊处理
    if (options.headers && options.headers['content-type']==="application/json;charset=utf-8") {
      options['body'] = JSON.stringify({ ...options.params, token });
    } else {
      defaultOptions.headers['content-type'] = "application/x-www-form-urlencoded";
      options['body'] = qs.stringify({ ...options.params, token });
    }
  } else if (options.method.toUpperCase() === "GET") {
    defaultOptions.headers['content-type'] = "application/json; charset=utf-8";
    url=url+(url.indexOf('?') !== -1?"&":"?")+qs.stringify({...options.params,token})
  }
  const newOptions = { ...defaultOptions, ...options };
  return fetch(url, newOptions)
      .then(checkStatus)
      .then(judgeOkState)
      .then(async res => {
        const cloneRes = await res.clone().json();
        Toast.hide();
        if (cloneRes.status === -1 ||
          cloneRes.status === -2 ||
          cloneRes.status === -3 ||
          cloneRes.status === -4 ||
          cloneRes.status === -5) {
          Toast.info(cloneRes.message, 1.5, () => {
            window.history.go(-1);
          });
        }
        // 没有token
        if (cloneRes.status === -8) {
          Toast.info(cloneRes.message, 1.5, () => {
            window.location.href = "/login";
          });
        }
        return res.json()
      })
      .catch(handleError);
}

export {
  request
};