import { useRequest } from 'ahooks';
import { useRef } from 'react';
import Icon from '@/components/Icon';
import FilePreview from './components/FilePreview';
import './FileView.less';
import { postReq } from '@/api/other';
interface IArrItem {
    isSign: boolean;
    name: string;
    value: string;
}
interface ListItem {
    businessId: string;
    path: string;
    title: string;
    url: string;
    parameter: IArrItem[];
}
interface Result {
    pageNo: number;
    pageSize: number;
    total: number;
    list?: ListItem[];
}
type K = {
    name: string;
    value: string;
};
export default function FileView(props: { url: string }) {
    const { url } = props;
    const urlReq = '/api/' + url.split('spi/')[1];

    const myPagerRef = useRef<HTMLDivElement>(null);
    const fileRef = useRef<any>(null);

    const { data, noMore } = useRequest(
        (d: Result | undefined) => {
            console.log(d);
            return postReq(urlReq, {
                pageNo: (d?.pageNo || 0) + 1,
                pageSize: 10,
                count: d?.total || 16
            });
        },
        {
            loadMore: true,
            ref: myPagerRef,
            isNoMore: d => {
                const { pageNo, pageSize, total } = d || { pageNo: 1, pageSize: 10, total: 10 };
                return pageSize * pageNo >= total;
            },
            formatResult: res => {
                console.log(res);
                return {
                    pageNo: res?.data?.pageNo,
                    pageSize: res?.data?.pageSize,
                    total: res?.data?.count,
                    list: res?.data?.list
                };
            }
        }
    );
    const { list = [] } = data || {};

    const download = (title: string, url: string) => {
        return () => {
            fileRef.current.show(title, `${url}`);
        };
    };
    return (
        <>
            <div className="pageContent" ref={myPagerRef}>
                {list.map((item, index) => (
                    <div className="item" key={index}>
                        <div className="itemInner">
                            <div className="baseInfo">
                                <p className="title">{item.title}</p>
                            </div>
                            <div className="download" onClick={download(item.title, item.url)}>
                                <Icon type="xiazai" color="#1e9fff" fontSize="14" />
                                <span>查看</span>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="bottomLoading">{!noMore ? null : <span>暂无更多数据</span>}</div>
            </div>
            <FilePreview fileRef={fileRef} />
        </>
    );
}
