import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequest } from 'ahooks';
import { inject, observer } from 'mobx-react';
import './index.less';
import Icon from '@/components/Icon';
import logo from '@/assets/images/login/logo.png';
import border from '@/assets/images/login/border.png';
import { getWeChatCode, userLogin, getUserInfo } from '@/api/getWeChatCode';
import { aes } from '@/utils/crypto';

//背景border
const borderStyle = {
    backgroundImage: `url(${border})`,
}
//错误提示
const initValue = {
    username: false,
    password: false
}
interface LoginParamsType {
    openId: string;
    username?: string;
    password?: string;
}
function Login(props: { appStore: any, props: any }) {
    const { history } = props.props;
    const { setBaseInfo } = props.appStore;
    const [errorState, setErrorState] = useState(initValue);
    const [openId, setOpenId] = useState("");
    const [isShowPassword, setShowPassword] = useState(false);  //false:不展示密码
    const { register, handleSubmit } = useForm();

    //微信h5 先获取openid  ---- 测试环境用下面的
    useRequest(
        async () => {
            const WeChatCodeRes = await getWeChatCode();
            if (!window.location.search) {
                //跳转至微信进行 静默授权,通过回调页面(还是当前页面) 保存 code即openid;
                const url = WeChatCodeRes.data
                window.location.href = url
            } else {
                //登录页 赋值  localStorage存
                const code = window.location.search.split('code=')[1].split('&')[0];
                const res = await getUserInfo({ code })
                localStorage.setItem('openid', res.data.openid);
                setOpenId(res.data.openid);
                // localStorage.setItem('openid', code);
                // setOpenId(code);
            }
        }
    );
    /* useRequest(
        async () => {
            const WeChatCodeRes = await getWeChatCode();
            console.log(WeChatCodeRes)
            if (!window.location.search) {
                //跳转至微信进行 静默授权,通过回调页面(还是当前页面) 保存 code即openid;
                // const url = WeChatCodeRes.data
                window.location.href = "http://192.168.33.150:9000/login?code=oK7XW51pKqPpxjGq3gisC2zbfusc"
            } else {
                //登录页 赋值  localStorage存
                const code = window.location.search.split('code=')[1].split('&')[0];
                // const res = await getUserInfo({ code })
                // localStorage.setItem('openid', res.data.openid);
                // setOpenId(res.data.openid);
                localStorage.setItem('openid', code);
                setOpenId(code);
            }
        }
    ); */
    //修改密码状态
    const changePwdState = () => {
        setShowPassword(!isShowPassword);
    }

    const { run } = useRequest(async (value) => {
        const useinfo = await userLogin(value);
        const { token, userId } = useinfo.data;
        //保存userId、token  
        setBaseInfo({ token, userId });
        history.replace("/");
    }, {
        manual: true,
    }
    );
    //提交
    const OnSubmit = (value: LoginParamsType) => {
        const { username, password } = value;
        if (!username || !password) {
            setErrorState({ username: !username, password: !password })
            return false;
        }
        setErrorState(initValue);
        const newValue = {
            ...value,
            password: aes(password),
        }
        run({ ...newValue, openId });
    }
    return (
        <div className="loginContainer">
            <header className="header">
                <div className="logoContent">
                    <div className="logoInner">
                        <img src={logo} alt="" />
                    </div>
                </div>
                <h4>欢迎登录金控业务管理系统</h4>
            </header>
            <div className="loginForm">
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className="loginForm_input" style={borderStyle}>
                        <label htmlFor="username" >
                            <Icon type="yonghuming" color="#1e9fff" fontSize="18" />
                            <input id="username" type="text" {...register("username")} placeholder="请输入用户名" autoComplete="off" />
                        </label>
                        <p className="error_tip">{errorState.username && '请输入用户名'}</p>
                        <label htmlFor="password" >
                            <Icon type="mima" color="#1e9fff" fontSize="18" />
                            <input id="password" maxLength={15} type={isShowPassword ? "text" : "password"} {...register("password")} placeholder="请输入密码" autoComplete="off" />
                            <Icon type={isShowPassword ? "zhengyan" : "biyan"} color="#1e9fff" fontSize="18" onClick={changePwdState} />
                        </label>
                        <p className="error_tip">{errorState.password && '请输入密码'}</p>
                    </div>
                    <input type="submit" value="登录" />
                </form>
            </div>
        </div>
    )
}
export default inject('appStore')(observer(Login));
