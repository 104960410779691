import { useState } from 'react';
import { useRequest } from 'ahooks'
import { getDetail } from '@/api/other';
import ToggleTabs from '@/components/ToggleTabs'; // tabs

type IData = {
    taskId?: string;
    businessKey?: string;
    projectId?: string;
    tabList?: any;
    opinion?: any;
    [propName: string]: any
}
export default function TodoListDetail(props: any) {
    const { history } = props.props;
    const url = "/api" + window.location.search.split('?getDataUrl=/spi')[1];
    const [detailData, setDetailData] = useState<IData>({});
    useRequest(
        async () => {
            const detailRes = await getDetail(url, {});
            const { data } = detailRes;
            setDetailData(data);
        }
    )
    return (
        <ToggleTabs tabList={detailData?.tabList} opinion={detailData?.opinion} taskId={detailData?.taskId} history={history} />
    )
}
