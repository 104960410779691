import { request } from '@/utils/request';

// 客户/列表
export function clientList(params:any) {
    return request('/api/client/list', {
        method: "post",
        params
    })
}
// 客户/详情
export function clientDetail(params:any) {
    return request('/api/client/detail', {
        method: "post",
        params
    })
}