import { Provider } from 'mobx-react'
import stores from '@/models'
import RouterIndex from '@/routes';
function App() {
  return (
    <Provider {...stores}>
      <RouterIndex />
    </Provider>
  )
}
export default App
