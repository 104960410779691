import './icon.css'
export default function Icon(props: { fontSize?: any; color?: any;type?: any;onClick?: any; }) {
    const {fontSize,color,type,onClick}=props;
    return (
        <svg className="icon" aria-hidden="true" style={{
            fontSize: `${fontSize}px`,
            color: `${color}`
        }} onClick={ onClick }>
            <use xlinkHref={`#icon-${type}`}></use>
        </svg>
    )
}