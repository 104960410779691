
import { ActionSheet, Button } from 'antd-mobile';
import ModalData from './Modal'
import './index.less'


export default function Opinion(props: { opinion: any; taskId: string; history: any }) {
    const { opinion, taskId, history } = props;

    const showModel = () => {
        const BUTTONS = <ModalData opinion={opinion} taskId={taskId} history={history} />;
        ActionSheet.showActionSheetWithOptions(
            {
                options: [],
                message: BUTTONS,
                maskClosable: false
            },
        );
    }
    return (
        <div className="handleOpinion">
            <Button type="primary" onClick={showModel}>审 批</Button>
        </div>
    )
}
