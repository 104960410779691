import CryptoJs from 'crypto-js';
// key
const secretKey = CryptoJs.enc.Utf8.parse('123456789ZDSSOFT')
// iv
const iv = CryptoJs.enc.Utf8.parse("0123456789ABCDEF")
// md5加密
export function md5(val:string) {
    return CryptoJs.MD5(val).toString()
}
// SHA1加密
export function sha1(val:string) {
    return CryptoJs.SHA1(val).toString()
}
// AES 加密
export function aes(val:string) {
    return CryptoJs.AES.encrypt(val, secretKey, {
        iv:iv,
        mode: CryptoJs.mode.CBC,  
        padding: CryptoJs.pad.Pkcs7
    }).ciphertext.toString()
}

