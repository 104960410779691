import { request } from '@/utils/request';

// 项目/列表
export function projectList(params:any) {
    return request('/api/project/list', {
        method: "post",
        params
    })
}
// 项目/详情
export function projectDetail(params:any) {
    return request('/api/project/detail', {
        method: "post",
        params
    })
}

// 业务品种查询
export function postReq(params:any) {
    return request('/api/project/businessList', {
        method: "post",
        params
    })
}