import { useRequest } from 'ahooks';
import { useRef } from 'react';
import Icon from '@/components/Icon';
import FilePreview from './components/FilePreview';
import './Guarantee.less';
import { postReq } from '@/api/other';
interface IArrItem {
    isSign: boolean;
    name: string;
    value: string;
}
interface ListItem {
    businessId: string;
    path: string;
    title: string;
    url: string;
    parameter: IArrItem[]
}
interface Result {
    pageNo: number;
    pageSize: number;
    total: number;
    list?: ListItem[];
}
type K = {
    name: string;
    value: string;
}
export default function Guarantee(props: { url: string; history: any }) {
    const { url, history } = props;
    const urlReq = "/api/" + url.split('spi/')[1];

    const containerRef = useRef<HTMLDivElement>(null);
    const fileRef = useRef<any>(null);
    const { data, noMore } = useRequest(
        (d: Result | undefined) => {
            return postReq(urlReq, {
                pageNo: (d?.pageNo || 0) + 1,
                pageSize: 10,
                count: d?.total || 0
            })
        },
        {
            loadMore: true,
            ref: containerRef,
            isNoMore: (d) => {
                const { pageNo, pageSize, total } = d || { pageNo: 1, pageSize: 10, total: 10 };
                return pageSize * pageNo >= total
            },
            formatResult: res => {
                return {
                    pageNo: res?.data?.pageNo,
                    pageSize: res?.data?.pageSize,
                    total: res?.data?.count,
                    list: res?.data?.list
                };
            }
        }
    );
    const { list = [] } = data || {};

    const goto = (url: string) => {
        return () => {
            if (url) {
                history.push(`/guaranteeMeasures/detail?getDataUrl=${url}`)
            }
        }
    }
    return (
        <>
            <div className="pageContent" ref={containerRef}>
                {list.map((item, index) => (
                    <div className="item_guarantee" key={index} onClick={goto(item.url)}>
                        <div className='itemInner_guarantee'>
                            <div className='baseInfo'>
                                {item.parameter.map((citem: any, idx: number) => {
                                    return (
                                        <p className='info' key={idx}><span>{citem.name}</span> {idx === 0 ? "" : ":"} <span>{citem.value}</span></p>
                                    )
                                })}
                            </div>
                            {
                                item.url && <div className='gotoLink'>
                                    <Icon type="zhixiangjiantou" color="#1e9fff" fontSize="14" />
                                </div>
                            }
                        </div>
                    </div>
                ))}
                <div className="bottomLoading">
                    {!noMore ? null : <span>暂无更多数据</span>}
                </div>
            </div>
            <FilePreview fileRef={fileRef} />
        </>
    );
};


