import Icon from '@/components/Icon';
import './StepsTime.less'
type Itype = {
    isRef: boolean;
    name: string;
    refInfo: null;
    [propName: string]: any
}
export default function StepsTime(props: { content: any }) {
    const { content } = props;
    const { details } = content[0];
    return (
        <div className="pageContent">
            {
                details.map((item: Itype, index: number) => {
                    return (
                        <div className="stepItem" key={index}>
                            <p className="time">{item.value.approvalTime}</p>
                            <div className="warpContent">
                                <div className="content">
                                    <p className="baseInfo">
                                        <span className="task">{item.value.task}</span>
                                        <span className="opinion">{item.value.opinion}</span>
                                    </p>
                                    <p className="approver">
                                        <Icon type="yonghuming" color="#9266f9" fontSize="14" />
                                        <span className="approverInfo">{item.value.approver}</span>
                                    </p>
                                    <p className='opinionDetails'>
                                        {item.value.opinionDetails}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

