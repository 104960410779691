import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequest } from 'ahooks';
import { Picker, List } from 'antd-mobile';
import { postReq } from '@/api/project';
import './index.less'
import qs from 'qs';

interface IParamsType {
    clientNm?: string;
    contractCd?: string;
    minInsuranceBalance?: string;
    maxInsuranceBalance?: string;
    projectMgrANm?: string;
}
interface ITypeData {
    id: string;
    name: string;
}

export default function ProjectSearch(props: any) {
    const { history } = props.props;
    const [typeData, setTypeData] = useState([]);
    const [businessTypeId, setbusinessTypeId] = useState(['']);
    const { register, handleSubmit } = useForm();

    useRequest(
        async () => {
            const res = await postReq({});
            const { list } = res.data;
            const typeDataList = list.map((item: ITypeData) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            setTypeData(typeDataList);
        }
    );
    // 选择器
    const changePicker = (val: any) => {
        setbusinessTypeId(val)
    }

    //查询
    const OnSubmit = async (value: IParamsType) => {
        const param = { ...value, businessTypeId: businessTypeId[0] };
        history.push(`/projectSearch/list?${qs.stringify(param)}`)
    }
    return (
        <div className='searchContent'>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <label htmlFor="clientNm" >
                    <div className='innerLabel'>
                        <span>客户名称</span>
                        <input id="clientNm" type="text" {...register("clientNm")} placeholder="请输入用户名称" autoComplete="off" />
                    </div>
                </label>
                {
                    !!typeData.length && <Picker data={typeData} cols={1} value={businessTypeId} className="forss" onChange={(v) => changePicker(v)}>
                        <List.Item arrow="horizontal">业务品种</List.Item>
                    </Picker>
                }
                <label htmlFor="contractCd" >
                    <div className='innerLabel'>
                        <span>合同编号</span>
                        <input id="contractCd" type="text" {...register("contractCd")} placeholder="请输入合同编号" autoComplete="off" />
                    </div>
                </label>
                <label htmlFor="minInsuranceBalance" >
                    <div className='innerLabel'>
                        <span>在贷余额</span>
                        <input id="minInsuranceBalance" type="number" {...register("minInsuranceBalance")} placeholder="最低" autoComplete="off" />--
                        <input id="maxInsuranceBalance" type="number" {...register("maxInsuranceBalance")} placeholder="最高" autoComplete="off" />元
                    </div>
                </label>
                <label htmlFor="projectMgrANm" >
                    <div className='innerLabel'>
                        <span>项目经理</span>
                        <input id="projectMgrANm" type="text" {...register("projectMgrANm")} placeholder="请输入项目经理名称" autoComplete="off" />
                    </div>
                </label>
                <div className='handleOpinion'>
                    <input type="submit" value="查询" />
                </div>
            </form>
        </div>
    )
}
