import { request } from '@/utils/request';

// 到期提醒/列表
export function expireList(params:any) {
    return request('/api/remind/expireList', {
        method: "post",
        params
    })
}
// 逾期提醒/列表
export function overdueList(params:any) {
    return request('/api/remind/overdueList', {
        method: "post",
        params
    })
}

// 检查提醒/列表
export function inspectList(params:any) {
    return request('/api/remind/inspectList', {
        method: "post",
        params
    })
}