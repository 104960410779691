import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'

import routers from './router'
// import Loading from '@/components/Loading';

const routes = [
    ...routers,
]

const renderChild=(route:any,props:any)=>{
    if (route.children){
        return (
            <route.component props={props}>
                <Switch>
                    {
                        route.children.map((child:any,i:number) => (
                            <Route
                                key={i}
                                path={child.path==="/"?route.path:child.path}
                                exact={child.exact}
                                // component={child.component}
                                render={(props)=>{
                                    return renderChild(child,props)
                                }}
                            />
                        ))
                    }
                <Redirect to='/404'></Redirect>
                </Switch>
            </route.component>
        )
    }else {
        document.title=route.name+"-金控业务管理系统"
        return (
        <route.component props={props}></route.component>
        )
    }
}

const RouterIndex:React.FC = () => {
    return (
         <Router>
            <Switch>
                    {
                        routes.map((route,index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact} 
                            render={(props) => {
                                return renderChild(route,props)
                            }}
                        />
                    ))
                    }
                    <Redirect from='/' to='/404'></Redirect>
                </Switch>
         </Router>
    )
}
export default RouterIndex