import Layout from '@/components/Layout'; //主要路由包裹
import OtherLayout from '@/components/OtherLayout'; //其他Layout
import Login from '@/pages/Login'; //登录页
import Home from '@/pages/Home'; //首页

import TodoList from '@/pages/TodoList'; //我的待办 委员投票
import TodoListDetail from '@/pages/TodoList/detail'; //我的待办 委员投票

import ExpireRemind from '@/pages/ExpireRemind'; //到期提醒
import OverdueRemind from '@/pages/OverdueRemind'; //逾期提醒
import CheckRemind from '@/pages/CheckRemind'; //检查提醒

import ProjectSearch from '@/pages/ProjectSearch'; //项目查询
import ProjectLayout from '@/pages/ProjectSearch/ProjectLayout'; //项目查询Layout
import ProjectList from '@/pages/ProjectSearch/list'; //项目查询列表
import ProjectDetail from '@/pages/ProjectSearch/detail'; //项目查询详情

import ClientSearch from '@/pages/ClientSearch'; //客户查询
import ClientLayout from '@/pages/ClientSearch/ClientLayout'; //客户查询Layout
import ClientList from '@/pages/ClientSearch/list'; //客户查询列表
import ClientDetail from '@/pages/ClientSearch/detail'; //客户查询详情

import GuaranteeMeasuresLayout from '@/pages/GuaranteeMeasures/guaranteeMeasuresLayout'; //保证措施Layout
import GuaranteeMeasuresDetail from '@/pages/GuaranteeMeasures/detail'; //保证措施详情

import ComprehensiveCredit from '@/pages/ComprehensiveCredit'; //综合授信

import Error from '@/pages/NotFound'; //404
type RouteItem={
  path?:string,
  component?:any,
  exact?:any,
  children?:RouteItem[],
  redirect?:string,
  name?:string,
  icon?:string
}

const routers:RouteItem[] = [
    {
        path: '/login',
        name: '登录',
        icon: 'login',
        component: Login,
    },
    {
        path: '/404',
        name: '404',
        icon: 'error',
        component: Error,
    },
    {
        path: '/', // 主要路由
        component: Layout,
        children:[
            {
                path: '/',
                name: '首页',
                icon: 'home',
                exact:true,
                component: Home,
            },
            {
                path: '/home',
                name: '首页',
                icon: 'home',
                exact:true,
                component: Home,
            },
            {
                path: '/todolist',
                name: '我的待办',
                component: OtherLayout,
                children:[
                    {
                        path: '/',
                        name: '我的待办',
                        icon: 'todolist',
                        exact:true,
                        component: TodoList
                    },
                    {
                        path: '/todolist/detail',
                        name: '待办详情',
                        icon: 'todolistDetail',
                        exact:true,
                        component: TodoListDetail
                    }
                ]
            },
            {
                path: '/committeeVote',
                name: '委员投票',
                component: OtherLayout,
                children:[
                    {
                        path: '/',
                        name: '委员投票',
                        icon: 'committeeVote',
                        exact:true,
                        component: TodoList
                    },
                    {
                        path: '/committeeVote/detail',
                        name: '委员投票详情',
                        icon: 'committeeVoteDetail',
                        exact:true,
                        component: TodoListDetail
                    }
                ]
            },
            {
                path: '/expireRemind',
                name: '到期提醒',
                icon: 'expireRemind',
                exact:true,
                component: ExpireRemind,
            },
            {
                path: '/overdueRemind',
                name: '逾期提醒',
                icon: 'overdueRemind',
                exact:true,
                component: OverdueRemind,
            },
            {
                path: '/checkRemind',
                name: '检查提醒',
                icon: 'checkRemind',
                exact:true,
                component: CheckRemind,
            },
            {
                path: '/projectSearch',
                name: '项目查询',
                component: ProjectLayout,
                children:[
                    {
                        path: '/',
                        name: '项目查询',
                        icon: 'projectSearch',
                        exact:true,
                        component: ProjectSearch
                    },
                    {
                        path: '/projectSearch/list',
                        name: '项目列表',
                        icon: 'ProjectList',
                        exact:true,
                        component: ProjectList
                    },
                    {
                        path: '/projectSearch/detail',
                        name: '项目详情',
                        icon: 'projectDetail',
                        exact:true,
                        component: ProjectDetail
                    }
                ]
            },
            {
                path: '/clientSearch',
                name: '客户查询',
                component: ClientLayout,
                children:[
                    {
                        path: '/',
                        name: '客户查询',
                        icon: 'clientSearch',
                        exact:true,
                        component: ClientSearch,
                    },
                    {
                        path: '/clientSearch/list',
                        name: '客户列表',
                        icon: 'clientList',
                        exact:true,
                        component: ClientList,
                    },
                    {
                        path: '/clientSearch/detail',
                        name: '客户详情',
                        icon: 'clientDetail',
                        exact:true,
                        component: ClientDetail,
                    }
                ]
            },
            {
                path: '/guaranteeMeasures', //保证措施列表
                name: '保证措施',
                component: GuaranteeMeasuresLayout,
                children:[
                    {
                        path: '/guaranteeMeasures/detail',
                        name: '保证措施详情',
                        icon:'guaranteeMeasuresDetail',
                        component: GuaranteeMeasuresDetail,
                    },
                ]
            },
            {
                path: '/comprehensiveCredit', //综合授信
                icon:'comprehensiveCredit',
                name: '综合授信',
                component: ComprehensiveCredit,
            }
        ]
    },
];

export default routers;