import './ClientLayout.less'

export default function Layout(props: any) {
    const { children } = props;
    return (
        <>
            {/* <h1>项目查询 头部</h1> */}
            {children}
            {/* <h1>项目查询 底部</h1> */}
        </>
    )
}