import { useState } from 'react';
import { useRequest } from 'ahooks'
import { getDetail } from '@/api/other';
import ShowInfo from '@/components/ToggleTabs/ShowInfo'; // tabs

type IData = {
    taskId?: string;
    businessKey?: string;
    projectId?: string;
    tabList?: any;
    opinion?: any;
    [propName: string]: any
}
export default function TodoListDetail(props: any) {
    const { history } = props.props;
    const url = "/api" + window.location.search.split('?getDataUrl=/spi')[1];
    const [content, setContent] = useState<IData>({});
    useRequest(
        async () => {
            const detailRes = await getDetail(url, {});
            const { tabList } = detailRes.data;
            setContent(tabList[0].content);
        }
    )
    return (
        <ShowInfo content={content} history={history} />
    )
}
