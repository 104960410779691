import { request } from '@/utils/request';

export function getWeChatCode() {
    return request("/api/wx/getCode", {
        method:"post"
    })
}

export function getWeChatOpenId(url:string) {
    return request(url, {
        method:'get'
    })
}

export function getUserInfo(params: any) {
    return request('/api/wx/getUserInfo', {
        method: 'get',
        params
    })
}

export function userLogin(params: any) {
    return request("/api/userLogin", {
        method: 'get',
        params
    })
}