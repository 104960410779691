import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import WarpCom from '@/components/WarpCom'
import { Toast } from 'antd-mobile';
import "./List.less";
import { useRequest } from 'ahooks';
import { getDetail } from "@/api/other";
import Icon from '@/components/Icon';
export default function List(props: { title: string; url: string }) {
    const history = useHistory();

    // console.log(props);
    const [pageNum, setPageNum] = useState(1);
    const [pageWidth, setPageWidth] = useState(10);
    const [pages, setPages] = useState([]);
    const [total, setTotal] = useState(0);
    const { title, url } = props;
    if (!url) {
        Toast.info('url为空', 1);
    }
    const reqUrl = "/api" + url.split('/spi')[1];
    const { run } = useRequest(
        async () => {
            const res = await getDetail(reqUrl, { pageNo: pageNum, pageSize: 10 });
            const { list, count, pageNo, pageSize } = res.data;
            setPageNum(pageNo + 1) //请求完后自动pageNo加1
            setPages(list)
            setTotal(count)
            setPageWidth(pageSize)
        }
    );
    const loadMore = () => {
        run();
    }

    const gotoLink = (path: any, url: any) => {
        // path 和 url都存在 则跳转
        if (path && url) {
            history.push(`${path}?getDataUrl=${url}`)
        }
    }

    const handleText = (value: string) => {
        let _value = value;
        _value = _value.replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&ge;/g, "≥")
            .replace(/&ldquo;/g, "“")
            .replace(/&rdquo;/g, "”")
            .replace(/&lsquo;/g, "‘")
            .replace(/&rsquo;/g, "’")
            .replace(/&quot;/g, "\"")
            .replace(/&permil;/g, "‰");
        return _value;
    }


    return (
        <WarpCom>
            <h4>{title}</h4>
            {
                pages && pages.map((item: { parameter: any, path: any, url: any }, idx: number) => {
                    return (
                        <div key={idx} className="list_content" onClick={() => gotoLink(item.path, item.url)}>
                            {
                                item.parameter && item.parameter.map((citem: { name: string, value: string, isSign: boolean }, index: number) => {
                                    return (
                                        !citem.isSign ? <p key={index} className='fieldItem'><span>{citem.name}</span> {index === 0 ? "" : ":"} <span dangerouslySetInnerHTML={{ __html: handleText(citem.value) }}></span></p> :
                                            <p key={index} className="fieldState">{citem.name}</p>
                                    )
                                })
                            }
                            {
                                <div className="info_list_jiantou">
                                    {(item.path && item.url) && <Icon type="zhixiangjiantou" color="#333333" fontSize="14" />}
                                </div>
                            }
                        </div>
                    )
                })
            }
            <div className="loadMore">
                {
                    ((pageNum - 1) * pageWidth >= total) ? <span className="noData">暂无更多数据</span> : <span className="hasData" onClick={loadMore}>加载更多</span>
                }
            </div>
        </WarpCom>
    )
}