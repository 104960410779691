import { useState } from 'react';
import { ActionSheet, Toast } from 'antd-mobile';
import { getHandler, submitProcessApprove } from '@/api/other';
import radio from '@/assets/images/opinion/radio.png';
import radioCheck from '@/assets/images/opinion/radio_check.png';
import TextArea from '@/components/TextArea';
import './Modal.less';
const radioImg = {
    backgroundImage: `url(${radio})`
};
const radioCheckImg = {
    backgroundImage: `url(${radioCheck})`
};
function ModalData(props: any) {
    const { opinion, taskId, history } = props;

    const [textValue, setTextValue] = useState();
    const [radioValue, setRadioValue] = useState();
    const [radioText, setRadioText] = useState('');

    //退回节点展示
    const [backNode, setBackNode] = useState([]);
    //节点选择
    const [currentNode, setCurrentNode] = useState();

    //退回人数据
    const [backPerson, setBackPerson] = useState([]);
    //退回人选择
    const [currentPerson, setCurrentPerson] = useState();

    // 退回节点
    const onChangeNode = async (e: any) => {
        setBackPerson([]);
        setCurrentPerson(undefined);
        setCurrentNode(e.target.value);
        const res = await getHandler({ taskId: taskId, nodeId: e.target.value });
        const { data } = res;
        setBackPerson(data);
    };

    // 退回人
    const onChangePerson = (e: any) => {
        setCurrentPerson(e.target.value);
    };

    // 签批选项
    const onChangeInput = (isBack: boolean, backNode: any, url: string) => {
        return async (e: any) => {
            setRadioText(e.target.attributes['data-text'].textContent);
            setRadioValue(e.target.value);
            // setBackNode([]);
            setCurrentNode(undefined);
            setCurrentPerson(undefined);
            setBackPerson([]);
            if (isBack) {
                setBackNode(backNode);
            } else {
                setBackNode([]);
            }
        };
    };
    const onChangeTextArea = (value: any) => {
        setTextValue(value);
    };

    const handleCancal = () => {
        ActionSheet.close();
    };

    const handleSubmit = async () => {
        let params = {
            taskId,
            opinionText: radioText, // 签批选项名字
            opinion: radioValue, // 签批选项值
            opinionContent: textValue, //签批意见
            nextUserId: currentPerson, //退回时传入的，处理人id
            processNextNode: currentNode
        }; //退回时传入的，退节点id};
        if (!params['opinion']) {
            Toast.info('请选择签批选项', 1);
            return false;
        }
        // 存在退回节点
        if (backNode.length) {
            if (!params['processNextNode']) {
                Toast.info('请选择退回节点', 1);
                return false;
            }
        }
        // 存在退回联系人
        if (backPerson.length) {
            if (!params['nextUserId']) {
                Toast.info('请选择退回联系人', 1);
                return false;
            }
        }

        if (!params['opinionContent']) {
            Toast.info('请填写签批意见', 1);
            return false;
        }
        const res = await submitProcessApprove(params);
        if (res.status === 0) {
            Toast.success(res.data.message, 1.5, () => {
                handleCancal();
                history.go(-1);
            });
        }
    };

    return (
        <div className="panel">
            <div className="panelItem handleOption_s">
                <p className="panelTitle">签批选项</p>
                <div className="radioContent">
                    {opinion.map((item: any, index: number) => {
                        return (
                            <div key={`option${index}`} className="ridioItem">
                                <input
                                    id={`option${index}`}
                                    data-text={item.name}
                                    type="radio"
                                    name="opr"
                                    value={item.value}
                                    onChange={onChangeInput(item.isBack, item.backNode, item.url)}
                                    checked={radioValue === item.value ? true : false}
                                    style={radioValue === item.value ? radioCheckImg : radioImg}
                                />
                                {/* <span className='icon_span' ></span> */}
                                <label htmlFor={`option${index}`}>{item.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
            {backNode && !!backNode.length && (
                <div className="panelItem rectNode">
                    <p className="panelTitle">退回节点</p>
                    <div className="radioContent">
                        {backNode.map((item: any, index: number) => {
                            return (
                                <div key={`node${index}`} className="ridioItem">
                                    <input
                                        id={`node${index}`}
                                        data-text={item.name}
                                        type="radio"
                                        name="opr1"
                                        value={item.value}
                                        onChange={onChangeNode}
                                        checked={currentNode === item.value ? true : false}
                                        style={
                                            currentNode === item.value ? radioCheckImg : radioImg
                                        }
                                    />
                                    {/* <span className='icon_span' ></span> */}
                                    <label htmlFor={`node${index}`}>{item.name}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {backNode && backPerson && !!backNode.length && !!backPerson.length && (
                <div className="panelItem personNode">
                    <p className="panelTitle">退回联系人</p>
                    <div className="radioContent">
                        {backPerson.map((item: any, index: number) => {
                            return (
                                <div key={`person${index}`} className="ridioItem">
                                    <input
                                        id={`person${index}`}
                                        data-text={item.name}
                                        type="radio"
                                        name="opr2"
                                        value={item.value}
                                        onChange={onChangePerson}
                                        checked={currentPerson === item.value ? true : false}
                                        style={
                                            currentPerson === item.value ? radioCheckImg : radioImg
                                        }
                                    />
                                    {/* <span className='icon_span' ></span> */}
                                    <label htmlFor={`person${index}`}>{item.name}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="panelItem">
                <p className="panelTitle">签批意见</p>
                <div className="radioContent">
                    <TextArea
                        rows={9}
                        maxLength={1000}
                        placeholder="请输入你的意见"
                        value={textValue}
                        onChange={onChangeTextArea}
                    />
                </div>
            </div>
            <div className="handleBtns">
                <span className="handleCancal" onClick={handleCancal}>
                    取消
                </span>
                <span className="handleSubmit" onClick={handleSubmit}>
                    提交
                </span>
            </div>
        </div>
    );
}
export default ModalData;
