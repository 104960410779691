import "./index.less";

export default function MenuItem(props: any) {
    const { history, path, icon, name, count, url } = props;
    const goto = (path: string) => {
        return () => {
            history.push(`${path}?getDataUrl=${url}`);
        }
    }
    return (
        <div className="menuItem" onClick={goto(path)}>
            <img src={require('@/assets/images/home/' + icon + '.png').default} alt="" />
            <p>{name}</p>
            {!!count && count !== "0" && <span>{count > 99 ? "99+" : count}</span>}
        </div>
    )
}