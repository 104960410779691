import { useState } from 'react';
import { Tabs } from 'antd-mobile';
import Opinion from '@/components/Opinion';
import ShowInfo from './ShowInfo' // 审批信息型
import StepsTime from './StepsTime' // 审批意见型
import FileView from './FileView' // 附件查看型
import Guarantee from './Guarantee' // 保证措施
import "./index.less"
import { Models } from 'rmc-tabs';

type ItemTpye = {
    title: string;
    type: string;
    url: string;
    content: ItemTpye[];
    [propName: string]: any;
}

export default function ToggleTabs(props: { tabList: ItemTpye[]; history: any; opinion: any, taskId: any }) {
    const [currentPage, setCurrentPage] = useState(0)
    const { tabList = [], history, opinion, taskId } = props;
    const tabs = tabList.map((item: ItemTpye) => {
        return {
            title: item.title
        }
    });
    const tabChange = (tab: Models.TabData, index: number) => {
        setCurrentPage(index)
    }
    return (
        <>
            <Tabs tabs={tabs} page={currentPage} onChange={tabChange} swipeable={false}>
                {
                    !!tabList.length && tabList.map((item, index) => {
                        const { content, type, url } = item;
                        switch (type) {
                            case "01":
                                return <div className={`pageContainer ${!opinion ? '' : 'showInfo'}`} key={index}><ShowInfo content={content} history={history} /></div>
                            case "02":
                                return <div className={`pageContainer ${!opinion ? '' : 'stepsTime'}`} key={index}><StepsTime content={content} /></div>
                            case "03":
                                return <div className={`pageContainer ${!opinion ? '' : 'fileView'}`} key={index}><FileView url={url} /></div>
                            case "04":
                                return <div className={`pageContainer ${!opinion ? '' : 'guarantee'}`} key={index}><Guarantee url={url} history={history} /></div>
                        }
                    })
                }
            </Tabs>
            {
                opinion && <Opinion opinion={(opinion && opinion)} taskId={(taskId && taskId)} history={history} />
            }
        </>
    )
}
