import { useState, useEffect } from 'react';
import Banner from '@/assets/images/home/top.png';
import './index.less';
import MenuItem from '@/pages/Home/components/MenuItem';
import { useRequest } from 'ahooks';
import { getMenus } from '@/api/home';



type IMenuType = {
    count: number;
    icon: string;
    index: number;
    name: string;
    path: string;
    url: string;
}

type MenuType = {
    title: string;
    menuList: IMenuType[]
}
const bannerStyle = {
    backgroundImage: `url(${Banner})`
}

// 回退
const handleBack = () => {
    return false;
}
// eslint-disable-next-line no-undef
// var window: Window;
export default function Home(props: any) {
    const { history } = props.props;
    const [menus, setMenus] = useState<MenuType[]>([]);
    useEffect(() => {
        window.history.pushState(null, "null", document.URL);
        console.log(window);
        window.addEventListener('popstate', handleBack, false);
        return () => {
            window.removeEventListener('popstate', handleBack, false);
        }
    }, [history]);

    useRequest(
        async () => {
            let menusRes = await getMenus({});
            const totalMenu: MenuType[] = [
                {
                    title: '我的任务',
                    menuList: []
                },
                {
                    title: '业务提醒',
                    menuList: []
                },
                {
                    title: '业务查询',
                    menuList: []
                }
            ]
            for (let item of menusRes.data) {
                if (item.path === "/projectSearch" ||
                    item.path === "/clientSearch") {
                    totalMenu[2].menuList.push(item)
                }
                if (item.path === "/expireRemind" ||
                    item.path === "/overdueRemind" ||
                    item.path === "/checkRemind") {
                    totalMenu[1].menuList.push(item)
                }
                if (item.path === "/todolist" ||
                    item.path === "/committeeVote") {
                    totalMenu[0].menuList.push(item)
                }
            }
            setMenus(totalMenu);
        }
    );
    return (
        <div className="homeContainer">
            <header className="homeBanner" style={bannerStyle}></header>
            <div className="menuContainer">
                {
                    menus.map((item: MenuType, index) => {
                        return (
                            <div className="menuPart" key={index}>
                                <h4>{item.title}</h4>
                                <div className="menuList">
                                    {
                                        item.menuList.length ? item.menuList.map(menuItem => {
                                            return (
                                                <div className="menuItemContainer" key={menuItem.index}>
                                                    <MenuItem
                                                        history={history}
                                                        path={menuItem.path}
                                                        icon={menuItem.icon}
                                                        name={menuItem.name}
                                                        count={menuItem.count}
                                                        url={menuItem.url}
                                                    />
                                                </div>
                                            )
                                        }) : <p className="nodata">暂无目录</p>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
