import { useState } from "react";
import Icon from '@/components/Icon';

function Tostr(props: { children: any; }) {
    const { children } = props;
    return <div dangerouslySetInnerHTML={{ __html: children }} />;
}
function ToFontHtml(props: { children: any; }) {
    const { children } = props;
    return <Tostr>{children}</Tostr>;
}

export default function Toggle(props: { children: any; isRef: any; refInfo: any; history: any }) {
    const [isOpen, setOpen] = useState(false)
    let { children = "", isRef, refInfo, history } = props;
    let isLength = false;

    children = (!children && children !== 0) ? "-" : children
    children = (typeof (children) === "number") ? String(children) : children
    if (children.length > 20) {
        isLength = true
    }
    // 符号转换
    children = children.replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&ge;/g, "≥")
        .replace(/&ldquo;/g, "“")
        .replace(/&rdquo;/g, "”")
        .replace(/&lsquo;/g, "‘")
        .replace(/&rsquo;/g, "’")
        .replace(/&quot;/g, "\"")
        .replace(/&permil;/g, "‰");
    const toFont = (arg: boolean) => {
        return () => {
            setOpen(arg)
        }
    }
    const goto = () => {
        if (isRef) {
            history.push(`${refInfo.path}?getDataUrl=${refInfo.infoUrl}`)
        }
    }
    return (
        !isLength ? (
            <div onClick={goto} style={{ display: 'flex', alignItems: 'center' }}>
                <div dangerouslySetInnerHTML={{ __html: children }}></div>
                {
                    isRef && <Icon type="zhixiangjiantou" color="#333333" fontSize="14" />
                }
            </div>
        ) : (
            !isOpen ?
                <pre>
                    {children.slice(0, 20)} <span onClick={toFont(true)}>展开</span>
                </pre> :
                <pre>
                    {children} <span onClick={toFont(false)}>收起</span>
                </pre>
        )
    )
}
