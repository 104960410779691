import React from 'react'

export default function GuaranteeMeasuresLayout(props: { children: any; }) {
    const { children } = props;
    return (
        <>
            {children}
        </>
    )
}
