function Layout(props: any) {
    const { history } = props.props;
    const location = window.location;
    // 判断是否进入我的待办  1.正常进入有openid  2.由推送进入url带openid 需要处理
    if (!localStorage.getItem('openid')) {
        const openId = location.search.split('&openId=')[1];
        if (location.pathname === '/todolist' && openId) {
            localStorage.setItem('openid', openId);
        } else {
            history.replace("/login")
        }
    }
    const { children } = props;
    return (
        <>
            {children}
        </>
    )
}
export default Layout;
