import './index.less'
export default function TextArea(props: any) {
    const { rows, maxLength, placeholder, value, onChange } = props;
    const s_value = !value ? '' : value;
    // let textLen = s_value.length;
    // const [len, setLen] = useState(textLen);
    const handleTextChange = (e: any) => {
        let value = e.target.value
        // setLen(value.length)
        onChange(value);
    }
    return (
        <div className="textarea_s">
            <textarea
                rows={rows}
                maxLength={maxLength}
                placeholder={placeholder}
                value={value}
                onChange={handleTextChange}
            ></textarea>
            <span>{s_value.length} / {maxLength}</span>
        </div>
    )
}
