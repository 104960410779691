import Detail from "./components/Detail";
import List from "./components/List";
import "./showInfo.less"
type IContentType = {
    url: string;
    type: string;
    title: string;
    [propName: string]: any;
}
export default function ShowInfo(props: { content: any; history: any }) {
    const { content, history } = props;
    return (
        <div className="pageContent">
            {
                content && content.length && content.map((item: IContentType, index: number) => {
                    switch (item.type) {
                        case "01":
                            return <div className="pageItem" key={index}><Detail title={item.title} details={item.details} history={history} /></div>
                        case "02":
                            return <div className="pageItem" key={index}><List title={item.title} url={item.url} /></div>
                    }
                })
            }
        </div>
    )
}
