import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks'
import { ActionSheet } from 'antd-mobile';
import { getDetail } from '@/api/other';
import ToggleTabs from '@/components/ToggleTabs'; // tabs

type IData = {
    taskId?: string;
    businessKey?: string;
    projectId?: string;
    tabList?: any;
    opinion?: any;
    [propName: string]: any
}
export default function TodoListDetail(props: any) {
    const { history } = props.props;
    const url = "/api" + window.location.search.split('?getDataUrl=spi')[1];
    const [detailData, setDetailData] = useState<IData>({});
    useRequest(
        async () => {
            const detailRes = await getDetail(url, {});
            const { data } = detailRes;
            setDetailData(data);
        }
    )
    useEffect(() => {
        history.listen(() => {
            //路由改变  modal关闭
            ActionSheet.close();
        })
    }, [history])
    return (
        <ToggleTabs tabList={detailData?.tabList} opinion={detailData?.opinion} taskId={detailData?.taskId} history={history} />
    )
}
