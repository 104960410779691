import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Toast } from 'antd-mobile';
import { Picker, List } from 'antd-mobile';
import { postReq } from '@/api/project';
import './index.less'
import qs from 'qs';

interface IParamsType {
    clientNm?: string;
    contractCd?: string;
    minInsuranceBalance?: string;
    maxInsuranceBalance?: string;
    projectMgrANm?: string;
}
interface ITypeData {
    id: string;
    name: string;
}

export default function ClientSearch(props: any) {
    const { history } = props.props;
    const [clientType, setClientType] = useState(['']);
    const { register, handleSubmit } = useForm();
    // 类型
    const typeData = [
        {
            value: '1',
            label: '个人客户'
        },
        {
            value: '2',
            label: '企业客户'
        }
    ]

    // 选择器
    const changePicker = (val: any) => {
        setClientType(val)
    }

    //查询
    const OnSubmit = async (value: IParamsType) => {
        const param = { ...value, clientType: clientType[0] };
        if (!param['clientType']) {
            Toast.info('请选择客户类型', 1.5);
            return false;
        }
        history.push(`/clientSearch/list?${qs.stringify(param)}`)
    }
    return (
        <div className='searchContent'>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <Picker data={typeData} cols={1} value={clientType} className="forss" onChange={(v) => changePicker(v)}>
                    <List.Item arrow="horizontal"><span style={{ color: 'red' }}>*</span>客户类型</List.Item>
                </Picker>
                <label htmlFor="clientNm" >
                    <div className='innerLabel'>
                        <span>客户名称</span>
                        <input id="clientNm" type="text" {...register("clientNm")} placeholder="请输入客户名称" autoComplete="off" />
                    </div>
                </label>
                <div className='handleOpinion'>
                    <input type="submit" value="查询" />
                </div>
            </form>
        </div>
    )
}
