import {action, computed, observable} from 'mobx'
import { IMobxStore,IType } from './IMobxStores';
class MobxStore implements IMobxStore {
    @observable baseInfo:IType = {
        token: '',
        userId: ''
    };
    
    @computed
    get greeting(): string {
        return ``
    };
    @action.bound
    setBaseInfo(baseInfo: IType): void{
        self.baseInfo = baseInfo;
        if (!self.baseInfo.token) {
            delete self.baseInfo.token
        }
        if (!self.baseInfo.userId) {
            delete self.baseInfo.userId
        }
        if (self.baseInfo.userId || self.baseInfo.token) {
            localStorage.setItem('baseInfo', JSON.stringify(self.baseInfo));
        } else {
            localStorage.removeItem("baseInfo");
        }
    }
}

const self = new MobxStore();

export default self;