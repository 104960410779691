import { request } from '@/utils/request';

export function getList(url:string,params:any) {
    return request(url, {
        method: "get",
        params
    })
}

export function getDetail(url:string,params:any) {
    return request(url, {
        method: "get",
        params
    })
}

// 获取退回节点处理人
export function getHandler(params:any) {
    return request('/api/workFlow/backHandler', {
        method: "post",
        params
    })
}

// 待办提交
export function submitProcessApprove(params:any) {
    return request('/api/workFlow/submitProcessApprove', {
        method: "post",
        params
    })
}

// 通用方法 post 
export function postReq(url:string,params:any) {
    return request(url, {
        method: "post",
        params
    })
}