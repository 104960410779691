import { useRequest } from 'ahooks';
import { useRef, memo } from 'react';
import qs from 'qs'
import './index.less';
import { projectList } from '@/api/project';
interface IArrItem {
    isSign: boolean;
    name: string;
    value: string;
}
interface ListItem {
    businessId: string;
    path: string;
    title: string;
    url: string;
    parameter: IArrItem[]
}
interface Result {
    pageNo: number;
    pageSize: number;
    total: number;
    list?: ListItem[];
}
type K = {
    name: string;
    value: string;
}
function List(props: any) {
    const url = window.location.search.split('?')[1];
    const params = qs.parse(url);
    const { history } = props.props;
    const goto = (url: string) => {
        return () => {
            history.push(`/projectSearch/detail?getDataUrl=${url}`);
        }
    }
    const containerRef = useRef<HTMLDivElement>(null);
    const { data, noMore } = useRequest(
        (d: Result | undefined) => {
            return projectList({
                ...params,
                pageNo: (d?.pageNo || 0) + 1,
                pageSize: 10,
                count: d?.total || 0
            })
        },
        {
            loadMore: true,
            ref: containerRef,
            isNoMore: (d) => {
                const { pageNo, pageSize, total } = d || { pageNo: 1, pageSize: 10, total: 10 };
                return pageSize * pageNo >= total
            },
            formatResult: res => {
                return {
                    pageNo: res?.data?.pageNo,
                    pageSize: res?.data?.pageSize,
                    total: res?.data?.count,
                    list: res?.data?.list
                };
            }
        }
    );
    const { list = [] } = data || {};
    return (
        <div className="listContainer projectList" ref={containerRef}>
            {list.map((item, index) => (
                <div className="item" key={index} onClick={goto(item.url)}>
                    <h3 className="title">{item.title}</h3>
                    <p>
                        {
                            item.parameter.map((pItem: K, index: number) =>
                                <span key={index}>{pItem.name}:{pItem.value}</span>
                            )
                        }
                    </p>
                </div>
            ))}
            <div className="bottomLoading">
                {!noMore ? null : <span>暂无更多数据</span>}
            </div>
        </div>
    );
};
export default memo(List)
